'use strict';


var queryString = (function(){

    function getObject(url) {
        var urlArray = url.split('=');
        var string = urlArray[1];
        string = decodeURIComponent(string);

        return JSON.parse(string);
    }

    return {
        getObject: getObject
    }

})();