/*  VenueQuery Object defines
    Foursquare API query variables
 ========================================== */

'use strict';

var venueQuery = {
    baseUrl:        'https://api.foursquare.com/v2/venues/explore',
    client_id:      'L4UK14EMS0MCEZOVVUYX2UO5ULFHJN3EHOFVQFSW0Z1MSFSR',
    client_secret:  'YKJB0JRFDPPSGTHALFOEP5O1NDDATHKQ2IZ5RO2GOX452SFA',
    v:              '20170801',
    radius:         '2000',
    section:        'coffee',
    ll:             '43.3190,21.9182', //'43.3041,21.9117',
    venuePhotos:    '1',
    sortByDistance: '1',
    openNow:        '1',


    // URL Method automatically creates query string
    url:  function()
    {
        /*  Checks if it is first prop after url and determins
        //  whether string '&' is going to be used or not
        ============================================================= */
        var isFirstProp = true;
        var queryUrl = this.baseUrl;

        for(var prop in venueQuery) {
            if(prop != 'baseUrl' && prop != 'url') {
                if(isFirstProp) {
                    queryUrl += `?${prop}=${venueQuery[prop]}`;
                    isFirstProp = false;
                } else {
                    queryUrl += `&${prop}=${venueQuery[prop]}`;
                }
            }
        }

        return queryUrl;
    } // end: url method
};