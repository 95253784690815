'use strict';

var venueItemsQuery = (function(){
    var baseUrl = 'https://api.foursquare.com/v2/venues/';
    var client_id = 'L4UK14EMS0MCEZOVVUYX2UO5ULFHJN3EHOFVQFSW0Z1MSFSR';
    var client_secret = 'YKJB0JRFDPPSGTHALFOEP5O1NDDATHKQ2IZ5RO2GOX452SFA';
    var v = '20170801'

    function url(venueID, category) {
        var urlString;
        urlString = baseUrl + venueID + '/' + category + '?client_id=' + client_id + '&client_secret=' + client_secret + '&v=' + v;

        return urlString;
    }

    return {
        url: url
    }

})();