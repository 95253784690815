'use strict';

var photoUrl = (function(){

    function get(object, size) {
        var prefix = object.prefix;
        var suffix = object.suffix;

        return prefix + size + suffix;
    }

    return {
        get: get
    }

})();