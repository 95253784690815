'use strict';

if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(getPosition);
} else {
    alert('This Browser doesn\'t support geolocation');
}

function getPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    console.log('Latitude:' + latitude + ' Longitude: ' + longitude);
    var currentLocation = latitude + ',' + longitude;
    console.log(currentLocation);
    venueQuery.ll = currentLocation;
    console.log(venueQuery.ll);
    getJSON.response(venueQuery.url(), caffeeShops);
}



function caffeeShops(response) {
    var responseArray = response.response.groups;
    var caffeeShop = responseArray[0].items;
    var caffeeShopElement;
    var imagePrefix = [];
    var imageSuffix = [];
    var imageLink = [];

    console.log(caffeeShop);

    caffeeShopElement = document.querySelector('.coffee-shops');
    caffeeShopElement.innerHTML = '';


    for(var i=0; i<caffeeShop.length; i++) {
        imagePrefix[i] = caffeeShop[i].venue.photos.groups[0].items[0].prefix;
        imageSuffix[i] = caffeeShop[i].venue.photos.groups[0].items[0].suffix;
        imageLink[i] = imagePrefix[i] + '300x300' + imageSuffix[i];

        var dataString = encodeURIComponent(JSON.stringify(caffeeShop[i]));
        var linkHref = "coffeeshop.html?venue=" + dataString;
        caffeeShopElement.innerHTML +=
            `<div class="row justify-content-center">
                <div class="col-md-8 col-sm-10 col-12">
                    <a href="${linkHref}">
                        <div class="coffee-shop ">
                            <div class="row">
                                <div class="col-md-4">
                                    <img class="img-fluid" src="${imageLink[i]}" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div>coffee shop</div> 
                                    <div class="name">${caffeeShop[i].venue.name}</div>
                                    <div class="row justify-content-end">
                                        <div class="col-md-4">
                                            <div class="distance-desc">distance</divclass> 
                                            <div class="distance">${caffeeShop[i].venue.location.distance}m</div>
                                        </div>
                                    </div>
                                </div>                         
                            </div> <!-- end: row -->
                        </div> <!-- end: coffee shop-->
                    </a>
                </div> <!-- col -->
            </div>`;
    }


}