var documentHeight = window.innerHeight;
console.log('browser height: ' + documentHeight);

document.querySelector('#main').style.minHeight = documentHeight;

window.addEventListener('scroll', function(){
    var winScrollPosition;
    var backgroundColor;
    var colorTransparency;
    var transparencyTrashold = 400;

    winScrollPosition = window.scrollY;


    colorTransparency = winScrollPosition / transparencyTrashold;
    (colorTransparency > 0.5) ? colorTransparency = 0.5 : colorTransparency = colorTransparency;
    // console.log('transparency: ' + colorTransparency);
    backgroundColor = `rgba(15,15,15,${colorTransparency})`;
    document.querySelector('nav').style.backgroundColor = backgroundColor;

});