//
// MODULE - AJAX request
//
// by Ivan D. Videnovic
// www.ivanwebdevelopment.com
//
//
// Usage:
// -------------------------------------------------------
// getJSON.response(APIurl, callBackFunction);
// 
//////////////////////////////////////////////////////////

'use strict';

var getJSON = (function () {
    var obj;
    var xhttp;
    
    function response(apiUrl, callBack){ 
        xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                callBack(JSON.parse(this.responseText));
            }
        };
        xhttp.open("GET", apiUrl, true);
        xhttp.send();   
    }

    return {
        response: response
    }

})();