'use strict';

var queryObject = queryString.getObject(window.location.search);


(function IIFE(coffee){
    var pageTitle = document.querySelector('title');
    pageTitle.innerHTML = coffee.venue.name;

    var descriptionElement = document.querySelector('.description');
    descriptionElement.innerHTML =
        `<div class="row">
            <div class="col-md-12">
                <h2>${coffee.venue.name}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>distance: ${coffee.venue.location.distance}m</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>price range: ${coffee.venue.price.tier} - ${coffee.venue.price.message}</p>
            </div>
        </div>`;

    var photosUrl = venueItemsQuery.url(coffee.venue.id, 'photos');
    var tipsUrl = venueItemsQuery.url(coffee.venue.id, 'tips');
    
    getJSON.response(photosUrl, venuePhotos);
    getJSON.response(tipsUrl, venueTips);

    function venuePhotos(response) {
        console.log(response);
        console.log(response.response.photos.items);

        var photoArray = response.response.photos.items;
        var photoObj;
        var photos = [];

        for (var i = 0; i < photoArray.length; i++) {
            photoObj = photoArray[i];
            photos[i] = photoUrl.get(photoObj, '300x300');

            console.log(photos[i]);
        }

        var photosNo = photos.length;
        console.log(photosNo);
        (photosNo > 10) ? photosNo = 10 : photosNo = photosNo;
        console.log(photosNo);

        var photosElement = document.querySelector('.photos');
        photosElement.innerHTML = '';
        console.log(photosElement);
        var photosHtml = ``;

        for (let i = 0; i < photosNo; i++) {
            photosHtml +=
                `
            <div class="col-md-2">
                <a href="${photos[i]}"><img class="img-fluid" src="${photos[i]}" alt="photo-${coffee.venue.name}-${i}"></a>
            </div>
            `;
        }

        photosHtml = '<div class="row">' + photosHtml + '</div>';
        photosElement.innerHTML = photosHtml;
    }
    
    function venueTips(response) {

        var tipsArray = response.response.tips.items;
        var tipObj='';
        var tipsElement = document.querySelector('.tips');
        tipsElement.innerHTML = '';

        console.log('tips Array: ');
        console.log(tipsArray);

        if(!tipsArray.length) {
            tipsElement.innerHTML =
                `
                <div>Sorry, no tips found!</div> 
                `;
        } else {
            for (var i = 0; i < tipsArray.length; i++) {
                tipsElement.innerHTML +=
                    `
                 <div>${tipsArray[i].text}</div>
                 <div>user: ${tipsArray[i].user.firstName} ${tipsArray[i].user.lastName}</div>
                 `;
            }
        }

    }

})(queryObject);

